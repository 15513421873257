.titleFilter {
    .input {
        width: 100%;
        height: 32px;
        border-radius: 4px;
        padding: 4px 16px;
        line-height: var(--pc-line-height-a);
    }

    .suffix {
        font-size: 16px;
        color: #999;
    }
}