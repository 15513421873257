.dropTimer {
    width: 100%;
    padding: 16px;
    background-color: var();
    border-radius: 0 0 8px 8px;
    background-color: var(--pc-base-background);
}

ul.timerList {
    display: flex;

    .filterItem {
        width: calc((100% - 16px) / 3);
        height: 36px;
        border-radius: 18px;
        background-color: #f7f7f8;
        border: 1px solid #f7f7f8;
        text-align: center;
        line-height: 36px;
        letter-spacing: 0.5px;
        color: #333;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .activeItem {
        color: var(--theme-color);
        border-color: var(--theme-color);
        background-color: rgba(44, 54, 222, 0.1);
    }
}

.timeSelect {
    margin-top: 8px;
    display: flex;
    align-items: center;

    .timeItem {
        width: calc((100% - 24px) / 2);
        height: 36px;
        border-radius: 18px;
        background-color: #f7f7f8;
        border: 1px solid #f7f7f8;
        box-sizing: border-box;
        text-align: center;
        line-height: 1.57;
        letter-spacing: 0.5px;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;

        &.activeItem {
            color: var(--theme-color);
            border: solid 1px var(--theme-color);
            background-color: rgba(44, 54, 222, 0.1);
        }

        &.errorItem {
            color: var(--pc-base-f7-color);
            border: solid 1px var(--pc-base-f7-color);
            background-color: rgba(254, 52, 52, 0.08);
        }
    }

    .timeSplit {
        width: 12px;
        height: 1px;
        margin: 0 6px;
        background-color: #333;
    }
}

.datePicker {
    margin-top: 8px;

    :global {
        .datepicker {
            position: static;
        }

        .datepicker.default {
            background-color: transparent;

            .datepicker-content {
                padding: 0;

                & > div {
                    margin: 0;

                    &:first-child .datepicker-wheel {
                        border-top-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    &:last-child .datepicker-wheel {
                        border-top-right-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }
                }
            }

            .datepicker-scroll li {
                color: #333;
                font-size: 14px;

                &:nth-of-type(6) {
                    font-weight: 500;
                    font-family: PingFangSC-Medium;
                }

                &:nth-of-type(4),
                &:nth-of-type(8) {
                    position: relative;

                    &::after {
                        content: '';
                        width: 100%;
                        height: 75%;
                        position: absolute;
                        left: 0;
                    }
                }

                &:nth-of-type(4) {
                    &::after {
                        top: 25%;
                        background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
                    }
                }

                &:nth-of-type(8) {
                    &::after {
                        bottom: 25%;
                        background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
                    }
                }
            }

            .datepicker-wheel {
                border: none;
                color: #333;
                background-color: #f3f3f3;
            }
        }
    }
}
