.analyst {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: sticky;
        background-color: #ebebeb;
    }

    &::after {
        bottom: 0;
    }
}

.analystList li {
    height: 92px;
    padding: 8px 16px 12px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: #f7f7f8;
    }

    &.active {
        background-color: #ebebeb;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        letter-spacing: 0.5px;

        h2 {
            width: calc(100% - 80px);
            font-size: var(--pc-font-size-16);
            font-weight: normal;
            line-height: var(--pc-line-height-a);
            color: #333;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        span {
            width: 80px;
            text-align: right;
            font-size: 12px;
            color: #999;
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        margin-top: 4px;
        letter-spacing: 0.5px;

        .symbolSign {
            margin-right: 8px;
            font-size: 14px;
            line-height: 1.57;
            color: #666;
        }

        .fluctuate {
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 12px;

            :global {
                .anticon {
                    font-size: 18px;
                }
            }

            .fName {
                margin: 0 8px;
            }
        }
    }

    .summary {
        margin-top: 4px;
        font-size: 12px;
        color: #999;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
