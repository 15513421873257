.searchItems {
    position: relative;
    padding: 24px 0 16px;
    background-color: var(--pc-base-background);

    .form {
        display: flex;
        flex-wrap: wrap;
    }

    :global {
        .ant-form-item {
            margin-right: 16px;
            margin-bottom: 16px;

            .ant-form-item-label > label {
                margin-right: 4px;
                color: #010b27;
                font-size: 16px;
                letter-spacing: 0.5px;
                text-align: left !important;

                &::after {
                    margin-inline-start: 6px;
                }
            }
        }
    }

    .title {
        margin-bottom: 0;
    }

    .timer {
        :global {
            .ant-form-item-label > label {
                width: 83px;
            }
        }
    }

    .trend {
        :global {
            .ant-form-item-label > label {
                width: 50px;
            }
        }
    }
}

.searchItemsEn {
    :global {
        .ant-form-item {
            .ant-form-item-label > label {
                margin-right: 0;
                font-size: 15px;

                &::after {
                    margin-inline-start: 6px;
                }
            }
        }
    }
}
