.lineItem {
    display: inline-block;
    height: 56px;
    line-height: 56px;
    margin-right: 40px;
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #000;

    &.active {
        position: relative;
        color: #2c36de;
        font-weight: 500;
        font-family: PingFangSC-Medium;

        &::after {
            content: ' ';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #2c36de;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
