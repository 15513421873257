.searchItems {
    padding: 8px 0;
    position: relative;
    background-color: #fff;

    .form {
        width: 100%;
        display: flex;
        position: relative;
        padding-left: 16px;
        box-sizing: border-box;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
            margin-right: 4px;
        }

        .ant-form-item-control,
        .ant-form-item-control-input {
            position: static !important;
            background-color: #f5f5f5;
        }
    }
}
