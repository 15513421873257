.customSelect {
    .suffix {
        transform-origin: 50% 50%;
        transform: rotateZ(90deg);
        font-size: 14px;
        color: #666;

        &.suffixOpen {
            transform: rotateZ(-90deg);
        }
    }

    :global {

        .ant-select-selector {
            padding: 0 8px !important;

            .ant-select-selection-search {
                inset-inline-start: 8px;
                inset-inline-end: 8px;
            }
        }



        // .ant-select-selection-placeholder {
        //     color: #666;
        // }

        .ant-select-selection-item {
            color: #333;
        }
    }

    &.customSelectActive {

        :global {

            .ant-select-selector {
                border-color: var(--pc-primary-color);
            }
        }
    }
}



.popSelect {
    top: 40px !important;
    padding: 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    :global {

        .ant-select-item-empty {
            padding: 0;
        }

        .ant-select-item-option {
            letter-spacing: 0.5px;
            // color: #666;
        }

        .ant-select-item {
            padding: 7px 12px;
        }

        .ant-select-item.ant-select-item-option-selected {
            font-weight: normal;
            background-color: #f7f7f8;
        }
    }
}

.nullData {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nullTxt {
        margin-top: 8px;
        font-size: 14px;
        color: #666;
    }

    :global {
        .ant-image {
            max-width: 100%;
        }
    }
}