.searchItems {
    width: 100%;
    height: 46px;
    position: relative;
    background-color: #f7f7f8;
    display: flex;
    justify-content: center;

    .form {
        width: 100vw;
        height: 100%;
        padding-top: 8px;
        display: flex;
        position: absolute;
        top: 0;
        background-color: #f7f7f8;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item-control,
        .ant-form-item-control-input {
            position: static !important;
        }
    }

    .title {
        :global {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
